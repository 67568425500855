import "./index.scss";

import { NavLink } from "react-router-dom";
import React from "react";

export default function SideNav() {
  return (
    <div className="sidenav">
      <nav>
        <NavLink to="/">home</NavLink>
        <NavLink to="about">about</NavLink>
        <NavLink to="projects">projects</NavLink>
        <NavLink to="blog">blog</NavLink>
        <NavLink to="games">games</NavLink>
      </nav>
    </div>
  );
}
