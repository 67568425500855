import "./index.scss";

import { Bounce } from "react-reveal";
import React from "react";
import SeeMore from "Components/SeeMore";
import img from "Assets/Images/darkback.jpg";

export default function BlogSection({ data, seemore }) {
  return (
    <div className="blogSection">
      {data && data.length ? (
        <>
          <SeeMore
            Extra={() => <h2>Articles</h2>}
            title="See more articles"
            action={seemore}
          />
          <div className="articles">
            <Bounce left top>
              <div className="article">
                <img alt="article" src={img} />
                <div className="imgSpace" />
                <div className="details">
                  <div>How to think about problems</div>
                  <p>
                    lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem
                  </p>
                </div>
              </div>
            </Bounce>
            <Bounce top right>
              <div className="article">
                <img alt="article" src={img} />
                <div className="imgSpace" />
                <div className="details">
                  <div>Fixing bugs easily</div>
                  <p>
                    lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem
                  </p>
                </div>
              </div>
            </Bounce>
            <Bounce bottom left>
              <div className="article">
                <img alt="article" src={img} />
                <div className="imgSpace" />
                <div className="details">
                  <div>Learning to build a framework</div>
                  <p>
                    lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem
                  </p>
                </div>
              </div>
            </Bounce>
            <Bounce bottom right>
              <div className="article">
                <img alt="article" src={img} />
                <div className="imgSpace" />
                <div className="details">
                  <div>Building packages</div>
                  <p>
                    lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem
                  </p>
                </div>
              </div>
            </Bounce>
          </div>
        </>
      ) : (
        <div>Please be patient. No article yet... </div>
      )}
    </div>
  );
}
