import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import About from 'Pages/About';
import Home from 'Pages/Home';
import React from 'react';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
