import "./index.scss";

import Navbar from "Components/Navbar";
import SideNav from "Components/SideNav";
import profile from "Assets/Images/profile.jpeg";

import React from "react";

function About() {
  return (
    <div className="About">
      <Navbar />
      <SideNav />
      <div>
        <h3>About Me</h3>
        <div className="content">
          <div className="rightSect">
            <p className="text">
              Howdy, I’m Ikechukwu Orji. I’m a software engineer living in
              Lagos, Nigeria. I am a fan of technology, arts, and programming.
              I’m also interested in web development and gaming.
            </p>
            <p className="text">
              Experienced Web Developer with a demonstrated history of working
              in the information technology and services industry. Skilled in
              Game Development, Leadership, Project Planning, Web Development,
              and Program Management. Strong information technology professional
              with a Bachelor's degree focused in Mathematics from Federal
              University of Technology Owerri.
            </p>
          </div>
          <div className="leftSect">
            <div className="image">
              <img src={profile} />
            </div>
            <a
              href="https://drive.google.com/file/d/16y3lcwOjXS80e6Ehi_8wgv7-npZjzlNQ/view?usp=sharing"
              target="_blank"
            >
              Resumé
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
