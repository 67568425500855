import * as Repos from "../constants/repos.constants";

import { SET_FORM, SET_INPUTS } from "../constants/inputs.constant";

const initialState = {
  allRepos: [],
  loading: false,
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SET_FORM}_REPO`:
      return {
        ...state,
        [action.form]: action.value,
      };
    case `${SET_INPUTS}_REPO`:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          [action.key]: action.value,
        },
      };
    case Repos.FETCH_REPOS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Repos.FETCH_REPOS_SUCCESS:
      return {
        ...state,
        allRepos: action.payload,
        loading: false,
      };

    case Repos.FETCH_REPOS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default stockReducer;
