import * as Repos from "../constants/repos.constants";

// import AxiosDefault from "../defaults/axiosInstance";
import actionCreator from "../../Utils/actionCreator";
import axios from "axios";

export const fetchRepos = () => async (dispatch) => {
  try {
    dispatch(actionCreator(Repos.FETCH_REPOS_REQUEST));
    const { data } = await axios.get(
      "https://api.github.com/users/jojitoon/repos?sort=created"
    );
    const repos = data.filter((d) => d.fork !== true && d.description);
    console.log(repos);
    // if (data.status === "success") {
    dispatch(actionCreator(Repos.FETCH_REPOS_SUCCESS, repos));
    // alert(data.stocks.length);
    // }
  } catch (error) {
    dispatch(actionCreator(Repos.FETCH_REPOS_FAILURE));
    console.log(error.stack, error.response);
  }
};
