import "./index.scss";

import { Bounce, Zoom } from "react-reveal";
import {
  FaEnvelope,
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaTwitterSquare,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

import BlogSection from "Components/BlogSection";
import Footer from "Components/Footer";
import { Link } from "react-router-dom";
import Navbar from "Components/Navbar";
import ProjectsSection from "Components/ProjectsSection";
import React from "react";
import SideNav from "Components/SideNav";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const { loading, allRepos } = useSelector(({ repoReducer }) => ({
    loading: repoReducer.loading,
    allRepos: repoReducer.allRepos,
  }));
  return (
    <div className="App">
      <Navbar />
      <SideNav />
      <section className="section first">
        <Bounce>
          <div className="content">
            Welcome. I am Ikechukwu Orji. <br />
            Hope you find this space interesting and worth your time.
          </div>
        </Bounce>
        <div className="socials">
          <Zoom>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/jojitoon"
            >
              <FaGithub className="git" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/jojitoon1"
            >
              <FaTwitterSquare className="twitter" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/ikechukwu-orji-0a5644142/"
            >
              <FaLinkedin className="linkedin" />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/joji.toon.7"
            >
              <FaFacebook className="facebook" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UC1k00aWVNbmy8XR2q9vKiWg"
            >
              <FaYoutube className="youtube" />
            </a>
            <a
              href="https://wa.me/2349069787848"
              // href="https://api.whatsapp.com/send?phone=+2349069787848"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="whatsapp" />
            </a>
            <a
              href="mailto:jojitoon@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaEnvelope className="mail" />
            </a>
          </Zoom>
        </div>
      </section>
      <section className="section next">
        <ProjectsSection data={allRepos} />
      </section>
      <section className="section ">
        <BlogSection />
      </section>
      <section className="section next">
        <Footer />
      </section>
    </div>
  );
}

export default App;
