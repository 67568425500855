import './index.scss';

import { NavLink } from 'react-router-dom';
import React from 'react';
import toggleTheme from 'Utils/toggleTheme';

export default function Navbar() {
  return (
    <div className='nav'>
      <nav>
        <NavLink to='/' className='myname'>
          joji
        </NavLink>
        {/* <NavLink to='about'>about</NavLink> */}
      </nav>
      <div className='toggle'>
        <input
          onClick={toggleTheme}
          type='checkbox'
          id='themeSwitch'
          name='theme-switch'
          className='theme-switch__input'
        />
        <label htmlFor='themeSwitch' className='theme-switch__label'>
          <span></span>
        </label>
      </div>
    </div>
  );
}
