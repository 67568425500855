import './index.scss';

import { Fade } from 'react-reveal';
import React from 'react';

export default function Footer() {
  return (
    <Fade bottom>
      <div className='footer'>
        Built with{' '}
        <span role='img' aria-label='image'>
          ❤️
        </span>{' '}
        by jojitoon @ {new Date().getFullYear()}
      </div>
    </Fade>
  );
}
