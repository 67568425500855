import "./index.scss";

import { Bounce } from "react-reveal";
import React from "react";
import SeeMore from "Components/SeeMore";
import img from "Assets/Images/darkback.jpg";

export default function ProjectsSection({ data, seemore }) {
  const langColors = {
    javascript: "",
    ruby: "",
  };
  return (
    <div className="projectsection">
      {data && data.length ? (
        <>
          <h2>Projects</h2>
          <div className="projects">
            {data.splice(0, 3).map((datum, i) => (
              <Bounce top delay={100 * i} key={i}>
                <a
                  href={datum.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="project"
                >
                  <span className="tag">{datum.language}</span>
                  <div className="details">
                    <div>{datum.name}</div>
                    <p>{datum.description}</p>
                  </div>
                </a>
              </Bounce>
            ))}
          </div>
          <SeeMore title="See more projects" action={seemore} />
        </>
      ) : (
        <div>Please be patient. Project not loaded... </div>
      )}
    </div>
  );
}
