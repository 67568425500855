import {combineReducers} from 'redux';
import authReducer from './auth.reducer';
import repoReducer from './repo.reducer';

const rootReducer = combineReducers({
  authReducer,
  repoReducer,
});

export default rootReducer;
