import './index.scss';

import React from 'react';

export default function SeeMore({ Extra, title, action }) {
  return (
    <div className='seemore'>
      <div>{Extra && <Extra />}</div>
      <button onClick={action}>{title}</button>
    </div>
  );
}
